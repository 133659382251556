import { default as homeI1OLyI0AamMeta } from "/home/node/app/pages/home.vue?macro=true";
import { default as nestedCategoryvLeDFG0rlrMeta } from "/home/node/app/pages/nestedCategory.vue?macro=true";
import { default as pingfiMV7lx5QTMeta } from "/home/node/app/pages/ping.vue?macro=true";
import { default as postOZkSnZhkP3Meta } from "/home/node/app/pages/post.vue?macro=true";
import { default as tagyICu5tT9g7Meta } from "/home/node/app/pages/tag.vue?macro=true";
import { default as topCategoryEsPFfEn42PMeta } from "/home/node/app/pages/topCategory.vue?macro=true";
export default [
  {
    name: "home",
    path: "/home",
    component: () => import("/home/node/app/pages/home.vue").then(m => m.default || m)
  },
  {
    name: "nestedCategory",
    path: "/nestedCategory",
    component: () => import("/home/node/app/pages/nestedCategory.vue").then(m => m.default || m)
  },
  {
    name: "ping",
    path: "/ping",
    meta: pingfiMV7lx5QTMeta || {},
    component: () => import("/home/node/app/pages/ping.vue").then(m => m.default || m)
  },
  {
    name: "post",
    path: "/post",
    component: () => import("/home/node/app/pages/post.vue").then(m => m.default || m)
  },
  {
    name: "tag",
    path: "/tag",
    component: () => import("/home/node/app/pages/tag.vue").then(m => m.default || m)
  },
  {
    name: "topCategory",
    path: "/topCategory",
    component: () => import("/home/node/app/pages/topCategory.vue").then(m => m.default || m)
  }
]