import type { ErrorEvent, EventHint } from '@sentry/types'

export function sentryBeforeSend(event: ErrorEvent, hint: EventHint) {
  if (hint.originalException && typeof hint.originalException == 'object') {
    if ('statusMessage' in hint.originalException) {
      console.info(`${new Date().toISOString()}\tCaptured error not sent to sentry: ${hint.originalException}`)
      return null
    }

    if ('fingerprint' in hint.originalException && typeof hint.originalException.fingerprint === 'string') {
      event.fingerprint = [hint.originalException.fingerprint]
    }
  }

  if (event.exception) {
    console.error(`${new Date().toISOString()}\t[user:${event.user?.id}]\t[Sentry captured exception][${event.event_id}]\t(${hint.originalException})` /* { event, hint } */)
  }
  else {
    console.log(`${new Date().toISOString()}\t[user:${event.user?.id}]\t[Sentry][${event.event_id}]\t(${event.message})`)
  }

  return event
}
