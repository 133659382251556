import {
  init,
  setContext,
  setUser,
  setTag,
  addBreadcrumb,
  captureMessage,
  captureException,
  browserTracingIntegration,
} from '@sentry/vue'
import { sentryBeforeSend as beforeSend } from '~/utils/sentryBeforeSend'
import { sentryIgnoreErrors as ignoreErrors } from '~/utils/sentryIgnoreErrors'

export default defineNuxtPlugin({
  name: 'ysc:sentry',
  parallel: true,
  setup() {
    const {
      public: {
        app: { version },
        sentry: { common: sentryCommonConfig, client: sentryClientConfig },
      },
    } = useRuntimeConfig()
    const { vueApp, ssrContext } = useNuxtApp()
    const router = useRouter()

    const sentry = {
      setContext,
      setUser,
      setTag,
      addBreadcrumb,
      captureMessage,
      captureException,
    }

    if (import.meta.server) {
      // $sentry object is defined by the nitro plugin in /server/plugins
      const sentryServer = ssrContext?.event.context.$sentry as typeof sentry
      return {
        provide: {
          sentry: sentryServer,
        },
      }
    }

    init({
      ...sentryCommonConfig,
      ...sentryClientConfig,
      app: vueApp,
      release: version,
      integrations: [
        browserTracingIntegration({
          router,
        }),
      ],
      tracePropagationTargets: ['localhost:3000'],
      ignoreErrors,
      beforeSend,
    })

    return {
      provide: {
        sentry,
      },
    }
  },
})
