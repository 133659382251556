<script setup lang="ts">
const email = ref()
const message = ref()

const { error, execute, status } = await useFetch('/internals/newsletter', {
  method: 'POST',
  immediate: false,
  body: {
    email,
  },
})

const { isLoading } = useIsLoading(status)

const { t } = useI18n()
const { $sentry } = useNuxtApp()
const onNewsletterSubmit = async (event: { email: string }) => {
  message.value = null
  email.value = event.email
  await execute()

  if (error.value) {
    if (error.value.data.statusCode !== 400) {
      $sentry.captureException('BlogNewsletterError')
      message.value = t('commons.error')
      return
    }
    type errorCode = 'email_already_exists' | 'duplicate_parameter' | 'email_is_invalid'
    const { code }: { code: errorCode } = error.value.data.data

    switch (code) {
      case 'email_already_exists': {
        message.value = t('data.newsletter.error.email_already_exists')
        break
      }

      case 'duplicate_parameter' : {
        message.value = t('data.newsletter.error.email_already_exists')
        break
      }

      case 'email_is_invalid' : {
        message.value = t('data.newsletter.error.email_is_invalid')
        break
      }

      default: {
        message.value = t('commons.error')
      }
    }
    return
  }

  message.value = t('data.newsletter.success')
}
</script>

<template>
  <div>
    <YscNewsletter
      class="flex-wrap"
      :loading="isLoading"
      @submit="onNewsletterSubmit"
    />
    <p
      v-if="message"
      class="mt-2 font-semibold"
      :class="[error ? 'text-red-500' : 'text-green-500']"
    >
      {{ message }}
    </p>
  </div>
</template>
