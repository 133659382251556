import { YscApiConsumer } from '@yescapa-dev/ysc-api-js/modern'
import type { $Fetch } from 'ofetch'

export const useRentalAPI = () => {
  // useNuxtApp is mandatory otherwise it will lead to error
  // error caught during app initialization Error: Must be called at the top of a `setup` function
  const { $i18n: { locale } } = useNuxtApp()
  const { public: { api: { rental: { url, key } } } } = useRuntimeConfig()

  const modernInstance = $fetch.create({
    onRequest({ options }) {
      options.headers.set('X-API-KEY', key)
      options.headers.set('Accept-Language', locale.value)
    },
  })

  const api = new YscApiConsumer(modernInstance as $Fetch, url)

  return { api }
}
