import type { RouterConfig } from '@nuxt/schema'

export default {
  // https://nuxt.com/docs/guide/recipes/custom-routing#using-approuteroptions
  // https://router.vuejs.org/guide/essentials/route-matching-syntax.html
  routes: (_routes) => {
    return [
      {
        name: 'home',
        path: '/',
        component: () => import('~/pages/home.vue'),
      },
      {
        name: 'tag',
        path: '/tags/:tag',
        component: () => import('~/pages/tag.vue'),
        meta: {
          middleware: 'page-one-permanent-redirect',
        },
      },
      {
        name: 'post',
        path: '/:topCategory+/p/:idPost',
        component: () => import('~/pages/post.vue'),
      },
      {
        name: 'topCategory',
        path: '/:topCategory',
        component: () => import('~/pages/topCategory.vue'),
      },
      {
        name: 'nestedCategory',
        path: '/:topCategory+',
        component: () => import('~/pages/nestedCategory.vue'),
        meta: {
          middleware: 'page-one-permanent-redirect',
        },
      },
      {
        name: 'ping',
        path: '/ping/front',
        component: () => import('~/pages/ping.vue'),
        meta: {
          layout: 'empty',
        },
      },
    ]
  },
} satisfies RouterConfig
