<script setup lang="ts">
const { menuLinks } = useMenuLinks()
const { socialLinks } = useSocialLinks()

await callOnce(async () => {
  const [routing, socials] = await Promise.all([
    $fetch('/internals/routing'),
    $fetch('/internals/socials'),
  ])
  menuLinks.value = routing
  socialLinks.value = socials
})

const { topLevelLocale: locale } = useTopLevelLocale()
const { format } = getOpenGraphLocaleFormatter()

useServerSeoMeta({
  charset: 'utf-8',
  viewport: 'width=device-width, initial-scale=1',
  author: 'Yescapa',
  appleMobileWebAppTitle: 'Yescapa',
  appleItunesApp: 'app-id=1043554869',
  ogSiteName: 'Yescapa Blog',
  twitterCard: 'summary_large_image',
  twitterSite: '@Yescapa',
  twitterCreator: 'Yescapa',
  ogType: 'website',
  colorScheme: 'normal',
  ogLocale: format(locale),
})

useServerHeadSafe({
  htmlAttrs: {
    lang: locale,
  },
})
</script>

<template>
  <div>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<style>
.page-enter-active,
.page-leave-active {
  transition: all 250ms;
}
.page-enter-from,
.page-leave-to {
  opacity: 0;
}
</style>
