<script setup lang="ts">
interface Props {
  showNav?: boolean
}

const props = withDefaults(defineProps<Props>(), { showNav: false })
defineEmits(['closeNav'])
const { menuLinks } = useMenuLinks()
</script>

<template>
  <ul class="flex flex-col lg:mt-3 lg:flex-row lg:space-x-4">
    <li
      v-for="(menu_item, i) in menuLinks['header-main']"
      :key="`header-main_mi_${i}`"
      class="flex-1 space-y-1 py-4"
    >
      <NuxtLink
        :to="menu_item.computed_url"
        class="block rounded px-3 py-2 text-lg font-semibold hover:bg-gray-100 focus-visible:z-10 focus-visible:ring-2 focus-visible:ring-blue-500 xl:text-xl"
        @click="$emit('closeNav')"
      >
        {{ menu_item.computed_title }}
        <span
          v-show="props.showNav"
          class="flex items-center space-x-2 text-sm  text-gray-500"
        >
          <span>{{ $t('links.all_posts') }}</span>
          <YscIconsArrowRight class="h-3 w-3" />
        </span>
      </NuxtLink>

      <ul v-show="props.showNav">
        <li
          v-for="(sub_menu_item, j) in menu_item.sub_menu"
          :key="`header-main_smi_${j}`"
        >
          <NuxtLink
            :to="sub_menu_item.computed_url"
            class="block rounded px-3 py-2  hover:bg-gray-100 focus-visible:z-10 focus-visible:ring-2 focus-visible:ring-blue-500"
            @click="$emit('closeNav')"
          >
            {{ sub_menu_item.computed_title }}
          </NuxtLink>
        </li>
      </ul>
    </li>
  </ul>
</template>
