import { RequestCreateNavigationEvent } from '@yescapa-dev/ysc-api-js/modern'
import type { APINavigationEventProperties } from '@yescapa-dev/ysc-api-js/modern'

export default defineNuxtRouteMiddleware((to, from) => {
  if (import.meta.server) {
    return
  }

  const navigationEventPayload: APINavigationEventProperties = {
    category: 'General',
    utm_campaign: queryValueToString(from.query.utm_campaign),
    utm_content: queryValueToString(from.query.utm_content),
    utm_medium: queryValueToString(from.query.utm_medium),
    utm_source: queryValueToString(from.query.utm_source),
    utm_term: queryValueToString(from.query.utm_term),
    referrer: queryValueToString(from.query.referrer),
    gb_visitor_token: queryValueToString(from.query.gb_visitor_token),
  }

  const { api } = useRentalAPI()
  try {
    // Don't await the request as we don't want it to be blocking
    api.send(new RequestCreateNavigationEvent({
      payload: [{
        path: to.path,
        event_type: 'page_viewed',
        created_at: new Date().toISOString(),
        event_properties: navigationEventPayload,
        user_properties: navigationEventPayload,
      }],
    }))
  }
  catch (e) {
    // Don't report on sentry as it could lead to an unwanted extra cost
    console.error(e)
  }
})
